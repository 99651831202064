.docs-header{
  .btn-new{
    margin-right: 16px;
  }
}
.learning-form-container{
  padding: 0 0 0 16px;
  width: 50%;
  min-width: 860px;
}
.trigger {
  .container{
    width: 68px;
  }
  .colon{
    margin-left: 4px;
    margin-right: 4px;
  }
}

.circular-form-container {
  padding: 0 20% 0 16px;
  .form-section {
    width: 60%;
    padding-right: 16px;
  }
  .circular-field .cke_editable {
    min-height: 100px;
    border: 1px solid #D9D9D9;
    padding: 4px 12px;
  }
  .circular-field.single-line .cke_editable{
    min-height: 0px;
  }
  .circular-field.closing_message{
    .label-cont{
      width: 92px;
    }
    .container-parent{
      margin-left: 48px;
    }
  }
  .label-cont {
    width: 140px;
  }

  [data-cke-editorplaceholder]::before {
    font-size: 1.4rem;
    color: rgba($color: #000, $alpha: 0.65);
  }
}

.circular-image {
  width: 450px;
  height: 950px;
}
.eval-container{
  padding-left: 40px;
}

.learning-preview {
  width: 0;
  transition: width ease-in-out 1s;
  &.show {
    width: 50%;
  }
  .title{
    margin: 8px 0 22px 0;
  }
}
.eval-preview{
  padding: 0px;
  width: 0;
  transition: all ease-in-out 1s;
  &.show {
    width: 50%;
    padding: 16px;
  }
  .title{
    margin: 8px 0 0 0;
  }
}
.upload-doc-dialog {
  padding: 2.4rem;
  width: 569px;
  // border-radius: 8px !important;
  &.edit{
    width: 400px;
  }
  .files-drop-zone {
    width: 100%;
    padding: 2.4rem;
    background: #FAFAFA;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    margin: 2.4rem 0;
    cursor: pointer;

    .icon {
      margin-bottom: 2.4rem;
      font-size: 4rem;
    }

    .text {
      line-height: 2.4rem;
    }
    .here{
      padding: 0;
      text-decoration: none;
    }
  }
  .file-upload-section{
    margin-top: 16px;
  }
  .title-input {
    margin: 12px 0;
    .label-cont{
      display: none;
    }
    .container{
      border-radius: 8px;
    }
  }
  .file-card {
    padding: 0.8rem 1.2rem;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    margin-bottom: 0.8rem;

    .btn-delete {
      box-shadow: none;
    }

    

    .font-input {
      margin: 0 0 0 12px;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      background: #FAFAFA;
      padding: 2px 4px;
      .container {
        background-color: #FFF;
        border: none;
        border-left: 1px solid #D9D9D9;
        &.disabled{
          background-color: #EFEFEF;
          cursor: not-allowed;
        }
      }
    }
    &.non-file{
      padding: 0;
      border: 0;
      .font-input{
        background-color: transparent;
        border: 0; 
      }
    }
  }
  .dialog-actions{
    box-shadow: none;
  }
  .warning{
    margin-right: 8px;
  }
  .trigger{
    .ant-form-item-control{
      flex-direction: row;
      align-items: center;
    }
    .select{
      width: 72px;
    }
    .help-text{
      margin-left: 8px;
    }
  }
}
.edu-list{
  .table-ct{
    padding-left: 0;
  }
}
.cke_float {
  width: 50vw !important;
  max-width: 600px;
}