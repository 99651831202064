.file-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    //
    padding: 8px 12px;
    border-radius: 4px;
    margin-top: 10px;
    border: 1px solid #D9D9D9;
}
.file-display .file-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.file-display .csv-icon {
    margin-right: 10px;
    color: #666;
    /* Darker grey for the icon */
}

.file-display .file-name {
    word-break: break-word;
    flex: 1;
    padding-right: 10px; 
    color: #333;
}

.file-display .icon-delete {
    margin-left: auto;
    cursor: pointer;
    color: #FF0000;
}

.section-wrapper {
    display: flex;
    align-items: start;
}

.details-section {
    border: 1px solid #D9D9D9;
    width: 75%;
    margin: 10px 20px 10px 0;
}

.allocation-section {
    border: 1px solid #D9D9D9;
    width: 25%;
    margin: 10px 10px 10px 0;
}

.section-header {
    border-bottom: 1px solid #D9D9D9;
    font-weight: 600;
    font-size: 16px;
    background-color: #FAFAFA;
    padding: 8px 8px 11px 15px;
}

.section-body {
    padding: 15px;
}

.section-padding {
    padding: 5px 0px 5px 0px !important;
    word-break: keep-all;
    // white-space: pre-wrap; //NJ24: discuss before removing this
    overflow-wrap: break-word;
    overflow-x: auto;
    text-align: justify;
}

.list-item {
    padding: 8px 15px;
}

.list-wrapper {
    padding-right: 25px;
}

.allocate-popup {
    width: 422px !important;
}
.deallocate-popup {
    width: 422px !important;
}

.archive-dialog {
    width: 422px !important;
}

.form-field {
    width: 150px !important;
    border: 1px solid #FF0000;
}

.suffix-icon {
    color: white;
    padding-right: 5px;
    font-size: 14px;
}

.suffix-icon-lite {
    color: inherit;
    padding-right: 5px;
    font-size: 14px;
}

.thick-icon {
    filter: drop-shadow(0 0 0.5px white);
    margin-bottom: -2px;
}

.thick-icon-lite {
    filter: drop-shadow(0 0 0.5px #0133CC);
}
.unallocate-icon {
    filter: drop-shadow(0 0 0.5px #FF0000);
    padding-right: 5px;
}
.pr1 {
    padding-right: 10px;
}

.pt2 {
    padding-top: 20px;
}

.pt1 {
    padding-top: 10px;
}

.mt2 {
    margin-top: 20px;
}

.mr1 {
    margin-right: 10px;
}

.folder-icon {
    font-size: 48px;
    margin-right: 20px;
}

.files-drag-drop-zone {
    width: 100%;
    padding: 1rem;
    background: #FAFAFA;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    margin: 2.4rem 0;
    cursor: pointer;

    .text {
        line-height: 2.6rem;
    }

    .here {
        padding: 0;
        text-decoration: none;
    }
}

.upload-information-wrapper {
    border: 0.5px solid rgb(200, 197, 197);
    width: 70%;
    margin-top: 10px;
}

.table-row {
    display: flex;
    border: 0.5px solid rgb(200, 197, 197);
    padding: 8px;
}

.heading-cell {
    width: 125px;
}

.value-cell {
    color: #1133CC;
}

.small-warning-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.archive-input {
    width: 30%;
}

.shadow-box-arc {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    height: 3px;
    margin: -35px 0px 12px 0px;
}

.ptb-1 {
    padding-top: 7px !important;
    padding-bottom: 8px !important;
}

.custom-input .ant-input-suffix {
    border-left: none;
}

.editor-wrapper {
    border: 0.5px solid #D9D9D9;
    padding: 5px 0px 20px 28px;
    margin-top: 10px;
}
.h-40 {
    height: 40px;
}
.ml2 {
    margin-left: 20px;
}
.ml7 {
    margin-left: 70px;
}
.mb16 {
    margin-bottom: 16px;
}
.paging-alignment {
    width: 90vw;
}