.doc-table {
  overflow-y: visible;

  .cell {
    width: 100%;
  }

  .cel-circle {
    width: 12px;
    height: 12px;
    margin-right: 0.8rem;
    border-radius: 50%;
  }
}

.table-ct {
  padding: 0.8rem;
}

.data-table-search {
  padding: 0.8rem;

  .input {
    margin-bottom: 0;
  }

  .label-cont {
    display: none;
  }

  .reset {
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    margin: 0 0.8rem;
  }
}

.ant-table-filter-column,
.ant-table-column-sorters {
  font: inherit !important;
  color: inherit !important;

  .ant-table-column-title {
    font: inherit !important;
    color: inherit !important;
  }
}

.ant-pagination-item {
  border: none !important;
  background-color: transparent !important;
}

.ant-pagination-item-active {
  .page-item {
    background-color: #1133CC;

    .page-no,
    .non-antd-icon {
      color: #fff;
    }
  }
}

.ant-pagination-disabled {
  .page-item {
    border-color: #D9D9D9;
  }
}

.page-item {
  border: 1px solid #1133CC;
  border-radius: 2px;
  min-width: 32px;
  height: 32px;
  padding: 0 6px;

  .non-antd-icon,
  .page-no {
    color: #1133CC;
  }
}

.doc-table .ant-table-thead>tr>th {
  position: relative;
}

.doc-table .ant-table-thead>tr>th::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  background-color: #bbb5b5;
  top: 20%;
  bottom: 20%;
}

.doc-table .ant-table-thead>tr>th:last-child::after {
  background-color: transparent;
}

.doc-table .ant-table-body {
  overflow-x: hidden !important;
}

.arc-table .ant-table-body  {
  overflow-y: auto !important;
}

.guide-table .ant-table-body  {
  overflow-y: auto !important;
}