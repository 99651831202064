.section-el {
    margin-top: 10px;
    margin-bottom: 10px;
}

.pt {
    padding-top: 3px;
}
.pr {
    padding-right: 15px;
}
.hr-section {
    border: 0;
    border-top: 0.5px solid #238787;
    margin: 25px 0 10px 0;
    width: 100%;
}

.video-image-template {
    position: relative;
    width:  680px !important;
    height: 480px !important;
  
    .video-container {
      position: relative;
      width: 100%;
      margin: auto;
      background: black;
      padding-bottom: 56.25%; /* 16:9 aspect ratio */
      height: 0;
    }
  
    .video-container > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    .video-container video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain; /* Ensure the video scales down to fit within the container */
    }
  }
  