.add-variable {
  width: 40vw;

  .label-cont {
    width: 80px;
  }

  .content {
    width: 100%;
    padding-top: 2rem;
  }
}

.new-content {
  .field-row {
    // width: 650px;
    margin: 0.8rem 0;
    padding-left: 5rem;
    flex-wrap: wrap;

    .font-input {
      width: 24%;

      .label-cont {
        min-width: 60px;
        margin-top: 0;

        .label {
          width: 100px;
        }

        .colon {
          margin: 0 8px;
        }
      }

      &:nth-child(even) {
        margin-left: 24px;

        .label-cont {
          min-width: 35px;

          .label {
            width: 35px;
          }
        }

        &.readOnly {
          margin-left: 0;
        }
      }

      &.readOnly {
        .container {
          padding-left: 2px;
        }

        .input {
          width: 80%;
          font-weight: bold;
        }
      }
    }

    .note-input {
      width: 100%;

      .colon {
        margin-right: 0.5rem;
      }
    }
  }
  .mode-select.zoom{
    min-width: 80px;
    margin-right: 8px;
  }
  .ck-container {
    margin-bottom: 2.4rem;
  }

  .section-heading {
    width: 12rem;
    display: flex;
    justify-content: flex-start;
  }

  .btn-add {
    width: auto;
    padding: 4px 8px;
    margin-top: 0.4rem;
    position: sticky;
    bottom: 0px;
  }

  &.doc {
    .ck-container {
      padding-left: 5.5rem;
    }
  }
}

.search-var {
  width: 40vw;

  .content {
    width: 99%;
    padding-top: 1.6rem;

    .btn-search {
      margin: 0 0 0.8rem 150px;
    }
  }

  .label-cont {
    width: 150px;
  }

  .font-input .label {
    padding-right: 2px;
  }

  .search-card {
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    padding: 0.8rem 1.6rem;
    margin: 4px 12px 8px 0;

    .btn {
      align-self: unset;
    }

    .source {
      width: 30%;
    }
  }

  .list {
    max-height: 300px;
    padding: 8px 16px 8px 8px;
  }
}

.discard-modal {
  width: 25vw;

  .title-sec {
    padding-left: 0;
    padding-bottom: 36px;
  }
}

.create-doc {
  width: 30vw;

  .title-sec {
    // padding-left: 2.8rem;
  }

  .content {
    padding: 0;
  }

  .text-input {
    .label {
      width: 27.5%;
    }

    .colon {
      margin-right: 2rem;
    }
  }
}

.archive-docs {
  // width: 20vw !important;

  .title-sec {
    color: #FE3333;
  }

  .warn {
    line-height: 1.6;
  }

  .label-cont {
    display: none;
  }

  .field-input {
    width: 60%;

    .container {
      border-radius: 8px;
    }

    .input {
      font-weight: bold;
    }
  }

  .warning {
    width: 3.4rem;
    height: 3.4rem;
    margin-right: 12px;
  }
}

.approval-dialog {
  // width: 30vw;

  .content {
    width: 99%;
    padding-top: 1.6rem;
  }

  .approval-input {
    .label {
      margin-right: 4px;
    }
  }
}

.delete-docs {
  // width: 500px;
}

.update-history {
  width: 40vw;

  .font-input .label {
    margin-right: 8px;
  }
}

.update-history-confirm {
  width: 428px;

  .dialog-actions {
    box-shadow: none;
  }
}

.history-dialog {
  flex-direction: column-reverse !important;

  .ant-table-cell {
    font-weight: 400 !important;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: 600 !important;
  }

  .dialog-actions {
    padding: 0.1rem 0 0 0;
    box-shadow: none;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .table-ct {
    padding-left: 0;
  }

  .title {
    margin-top: 0px;
  }

  .title-sec {
    display: none;
  }

  .note {
    max-height: 180px;
    overflow-y: auto;
  }

  .content {
    padding: 0;
  }

  .gap {
    margin-top: 2.8rem;

    .label {
      min-width: 100px;
    }
  }

  .field:nth-child(even) {
    width: 65%;

    .label {
      min-width: 80px;
    }
  }

  .field:nth-child(odd) {
    width: 35%;

    .label {
      width: 100px;
    }
  }

  .history {
    margin-top: 3.5rem;
    max-height: 360px;
  }
}

.present-bpr-tbl {
  margin-bottom: 0.8rem;
}

.dot-menu {
  .icon-dot {
    font-size: 3px;
  }
}

.status-popup {
  width: 28vw;

  .MuiDialogActions-root {
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: none;

    .btn:nth-child(2) {
      margin-left: 0;
    }
  }

  .title-sec {
    padding: 0 1.6rem 1.6rem 0;
    color: #33CC77;
  }
}

.cke_float {
  width: 100vw !important;
  max-width: 990px;
}
